@import "../../main.scss";

.section-actual-projects {
  height: 110rem;
  background-color: $color-background-dark;

  @include respond(phone) {
    height: min-content;
  }

  .hide-project {
    display: none;
  }

  .project {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 50rem;
    margin: 0 auto;
    margin-top: 15rem;
    width: min(80%, 150rem);
    background-color: $color-background-light;
    animation: fade 600ms ease-in-out;

    @include respond(tab-port) {
      grid-template-columns: 1fr;
      grid-template-rows: 30rem min-content;
    }

    @include respond(phone) {
      margin-top: 8rem;
    }


    &__images {
      display: flex;
      flex-direction: column;
      position: relative;
      width: 100%;

      @include respond(tab-port) {
        flex-direction: row;
      }

      @include respond(phone) {
        justify-content: center;
        margin-top: 2rem;
        position: relative;
      }

      // &--img1,
      // &--img2 {
      //   animation: swipe 500ms ease-in-out;
      // }

      &--img1 {
        position: absolute;
        top: 0;
        transform: translate(10%, -20%);

        @include respond(tab-port) {
          position: relative;
          transform: translate(-5%, -20%);
        }

        @include respond(phone) {
          transform: translate(0, 0);
        }
      }

      &--img2 {
        position: absolute;
        bottom: 0;
        right: 0;
        transform: translateY(20%);

        @include respond(tab-port) {
          position: relative;
          transform: translate(5%, -20%);
        }

        @include respond(phone) {
          transform: translate(0, 0);
        }
      }

      img {
        height: max(20rem, 30rem);
        width: max(30rem, 50rem);
        max-width: 91%;

        object-fit: cover;
        border-radius: 8px;
        box-shadow:
          0px 0px 3.1px rgba(0, 0, 0, 0.007),
          0px 0px 7.4px rgba(0, 0, 0, 0.011),
          0px 0px 14px rgba(0, 0, 0, 0.017),
          0px 0px 25px rgba(0, 0, 0, 0.03),
          0px 0px 46.8px rgba(0, 0, 0, 0.058),
          0px 0px 112px rgba(0, 0, 0, 0.17);

        @include respond(tab-port) {
          height: auto;
          width: 100%;
        }
      }

      .photo-dots {
        position: absolute;
        bottom: 0;
        left: 35%;
        transform: translate(50%, -40%);
        padding: 0;

        li {
          background: transparent;
          border: rgb(231, 170, 170) 1px solid;
        }

        .dot-active {
          background: $color-red-7;
        }
      }
    }

    &__info {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-evenly;
      padding: 0 5rem;

      @include respond(tab-port) {
        grid-row: 2/3;
        grid-column: 1/3;
        justify-content: flex-start;
        gap: 2.2rem;
      }

      @include respond(phone) {
        margin-top: 3rem;
      }

      &--title {
        color: $color-red-7;
        font-size: 4.4rem;

        @include respond(big-desktop) {
          font-size: 3.6rem;
        }
      }

      &--description {
        text-align: start;
        font-size: 2.4rem;
        color: $color-text-dark;
        line-height: 40px;

        @include respond(big-desktop) {
          font-size: 2rem;
        }
      }

      &--button {
        background-color: $color-red-7;
        font-size: 3rem;
        padding: 2rem 3.5rem;
        text-decoration: none;
        color: #fff;
        border-radius: 7px;

        @include respond(big-desktop) {
          font-size: 2.4rem;
        }

        @include respond(phone) {
          margin-bottom: 5rem;
        }
      }
    }
  }


  .dots {
    display: flex;
    justify-content: center;
    margin-top: 10rem;
    margin-bottom: 15rem;

    @include respond(tab-port) {
      margin-top: 7rem;
    }

    @include respond(phone) {
      margin: 0;
      padding: 5rem;
    }

    li {
      width: 12px;
      height: 12px;
      background: gray;
      border-radius: 50%;
      margin: 0 6px;
      cursor: pointer;
      list-style: none;
    }

    .dot-active {
      background: $color-red-7;
    }
  }

}

@keyframes fade {
  from {
    opacity: 0;
  }

  to {
    transform: 1;
  }
}

@keyframes swipe {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(100%);
  }
}