@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Vollkorn:wght@400;500;600;700;800&display=swap');
/*
  Font sizes (px)
10 / 12 / 14 / 16 / 18 / 20 / 24 / 30 / 36 / 44 / 52 / 62 / 74 / 86 / 98

  Spacing system (px)
2 / 4 / 8 / 12 / 16 / 24 / 32 / 48 / 64 / 80 / 96 / 128
*/

$red-logo-color: #cb2027;
$color-red-3: #ffa8a8;
$color-red-4: #ff8787;
$color-red-5: #ff6b6b;
$color-red-6: #fa5252;
$color-red-7: rgb(240, 62, 62);
$color-red-8: #e03131;
$color-red-9: #c92a2a;
$color-background-dark: #ececec;
$color-background-light: #fff;
$color-text-dark: #6d6c6c;
// #ececec
// #6b6b6b

$color-grey-light: #212121;
$color-grey-dark: #1c1c1c;

// RESPONSIVE BREAKPOINTS
$bp-largest: 75em; // 1200px
$bp-large: 62.5em; // 1000px
$bp-medium: 50em; // 800px;
$bp-small: 37.5em; // 600px;

//MIXIN
@mixin respond($breakpoint) {

  //600px
  @if $breakpoint ==phone {
    @media only screen and (max-width: $bp-small) {
      @content
    }

    ; //
  }

  //800px
  @if $breakpoint ==tab-port {
    @media only screen and (max-width: $bp-medium) {
      @content
    }

    ; //
  }

  //1000px
  @if $breakpoint ==tab-land {
    @media only screen and (max-width: $bp-large) {
      @content
    }

    ; //
  }

  //1200px
  @if $breakpoint ==big-desktop {
    @media only screen and (max-width: $bp-largest) {
      @content
    }

    ; //
  }
}


/* You can add global styles to this file, and also import other style files */
*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
  font-size: 62.5%; // 10px/16px = 62.5% -> 1rem = 10px
  font-family: 'Montserrat', 'Roboto', sans-serif;
  scroll-behavior: smooth;

  @include respond(tab-land) {
    font-size: 50%;
  }
}

.hide {
  animation: up_slide_effect 500ms ease-in-out;
  opacity: 0;
}

.titles {
  width: min(100%, 150rem);
  margin: auto;
  padding-top: 12.8rem;
  padding-left: 4rem;
  display: flex;
  opacity: 0;

  @include respond(big-desktop) {
    padding-left: 3rem;
  }

  span {
    background-color: rgb(240, 62, 62);
    width: 20px;
    height: 5px;
    margin-left: 1.5rem;
    align-self: center;
  }

  p {
    font-size: 5.2rem;
    color: $color-red-7;
    font-weight: bold;
    text-align: left;
    margin: 0 1rem;
    text-transform: uppercase;

    @include respond(big-desktop) {
      font-size: 4.4rem;
    }
  }
}

.title-fadein {
  animation: title_slide_effect 700ms ease-in-out;
  opacity: 1;
}

.element_1_slide_effect {
  animation: down_slide_effect 400ms ease-in-out;
  opacity: 1;
}

.element_2_slide_effect {
  animation: down_slide_effect 700ms ease-in-out;
  opacity: 1;
}

.element_3_slide_effect {
  animation: down_slide_effect 1000ms ease-in-out;
  opacity: 1;
}

//ANIMATIONS

@keyframes title_slide_effect {
  0% {
    transform: translateX(-15%);
    opacity: 0;
  }

  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes down_slide_effect {
  0% {
    transform: translateY(50%);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes up_slide_effect {
  0% {
    transform: translateY(0);
    opacity: 1;
  }

  100% {
    transform: translateY(50%);
    opacity: 0;
  }
}