@import "../../main.scss";

.invisible {
  visibility: hidden;
}

.introduction {
  font-size: 1.6rem;
  height: 100vh;
  width: 100vw;
  position: relative;
  background: linear-gradient(rgba(34, 34, 34, 0.8), rgba(34, 34, 34, 0.8));
  display: flex;
  flex-direction: column;

  &__content {
    margin: auto;
    color: #fff;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-height: 33rem;

    @include respond(tab-port) {
      height: 30rem;
    }

    @include respond(phone) {
      height: 28rem;
    }


    // background: linear-gradient(rgba(34, 34, 34, 0.6), rgba(34, 34, 34, 0.6));
    // border-radius: 5px;
    // border: 3px solid rgba(34, 34, 34, 0.6);

    p {
      font-size: 2.4rem;
    }

    &--info {
      display: none;
      margin: 1.6rem 0;
      padding: 2.4rem;
      border-radius: 5px;
      overflow: hidden;

      // transform: translateX(-100%);
      // background: linear-gradient(rgba(34, 34, 34, 0.6), rgba(34, 34, 34, 0.6));
      // background: linear-gradient(rgba(75, 75, 75, 0.4), rgba(145, 142, 142, 0.4));

      .info__title {
        font-size: 6.2rem;
        color: $color-red-8;

        @include respond(tab-port) {
          font-size: 5.2rem;
        }

        @include respond(phone) {
          font-size: 4.4rem;
        }
      }

      .info__description {
        font-size: 3.6rem;

        @include respond(tab-port) {
          font-size: 3.0rem;
        }

        @include respond(phone) {
          font-size: 2.4rem;
        }
      }
    }

    &--active {
      display: block;
      transform: translateX(-50%);
      opacity: 0;
      animation: test 5000ms ease-in-out 0ms;
      overflow: hidden;
      width: 100vw;



    }

    &--button {
      padding: 1.2rem 1.8rem;
      margin: 1rem 2rem;
      font-size: 2.4rem;
      width: 25rem;
      background-color: $color-red-8;
      color: #fff;
      border: none;
      border-radius: 4px;
      cursor: pointer;
      transition: transform 200ms ease-in-out;
      font-weight: bold;
      animation: visibility_animation 2500ms ease-in-out 0ms;

      @include respond(phone) {
        width: 19rem;
        font-size: 2rem;
        height: 5rem;
        padding: .5rem;
      }

      &:hover {
        transform: scale(1.1);
        transition: transform 200ms ease-in-out;
      }
    }

    &--button:nth-child(2) {
      background-color: rgb(214, 213, 213);
      color: $color-red-8;
    }
  }


  .introduction__background {
    position: absolute;
    z-index: -1;

    img {
      display: block;
      height: 100vh;
      width: 100vw;
      object-fit: cover;
      object-position: center;
    }

    .active-img {
      opacity: 1 !important;
      // transition: opacity 1s linear;
      transition-delay: 0ms;

    }

    .fade {

      position: fixed;
      opacity: 0;
      // transform: translateX(50%);
      transition: opacity 2s ease-in-out;
      transition-delay: 2s;
      // animation-name: fade;
      // animation-duration: 6s;
      // animation: fade 5s linear;
      // animation: test 1.5s linear 2s;
    }

    @keyframes fade {
      0% {
        opacity: 0;
      }

      30% {
        opacity: 1;
      }

      70% {
        opacity: 1;
      }

      100% {
        opacity: 0;
      }
    }

    @keyframes textSlide {
      0% {
        transform: translateX(-150%);
      }

      100% {
        transform: translateX(0);
      }


    }

    @keyframes test {
      0% {
        transform: translateX(-50%);
        opacity: 0;
      }

      25% {
        transform: translateX(0);
        opacity: 1;
      }

      75% {
        transform: translateX(0);
        opacity: 1;
      }

      100% {
        transform: translateX(50%);
        opacity: 0;
      }
    }

    @keyframes visibility_animation {
      0% {
        opacity: 0;
      }

      100% {
        opacity: 1;
      }
    }
  }
}