@import "../../main.scss";


.contact-section {
  font-size: 2rem;
  // background-image: url("../../assets/img/prilba.jpg");
  // background-repeat: no-repeat;
  // background-attachment: fixed;
  // background-position: center;
  height: 75rem;
  color: #fff;
  position: relative;

  @include respond(tab-port) {
    height: max(75rem, 100rem);
  }

  &__shadow {
    width: 100%;
    height: 100%;
    opacity: 0.8;
    position: absolute;
    background-color: #252525;
  }

  .contact-wrapper {
    position: absolute;
    z-index: 9999;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-top: 12.8rem;

    &__title {
      font-size: 5.2rem;
      font-weight: bold;
    }


    .contact {
      display: flex;
      justify-content: center;
      width: min(100%, 150rem);
      margin-top: 9.6rem;
      column-gap: 5rem;

      @include respond(tab-land) {
        width: min(100%, 75rem);
      }

      @include respond(tab-port) {
        width: min(55rem, 70rem);
        flex-wrap: wrap;
      }

      @include respond(phone) {
        width: min(40rem, 55rem);
      }

      &__form {
        display: flex;
        flex-direction: column;
        gap: 2rem;
        width: 50rem;

        @include respond(tab-port) {
          width: 40rem;
          margin-top: 5rem;
        }

        @include respond(phone) {
          width: 35rem;
        }

        &--input {
          height: 40px;
          font-size: 1.4rem;
          padding: 15px 12px;
          border-radius: 10px;

          &::placeholder {
            color: rgb(63, 59, 59);
            text-transform: uppercase;
          }

          &:focus {
            outline: 1.5px solid $color-red-7;
            transition: all 0.3s linear;
          }
        }

        &--area {
          height: 80px;
          resize: none;
          font-size: 1.4rem;
          padding: 15px 12px;
          border-radius: 10px;

          &::placeholder {
            color: rgb(63, 59, 59);
            text-transform: uppercase;
          }

          &:focus {
            outline: 1.5px solid $color-red-7;
            transition: all 0.3s linear;
          }
        }

        &--button {
          width: 15rem;
          height: 5rem;
          font-size: 2rem;
          background-color: $color-red-7;
          border: none;
          color: #fff;
          font-size: 2.4rem;
          border-radius: 7px;
        }

        &--button:hover {
          background-color: #fff;
          color: $color-red-7;
          transition: .3s;
        }
      }

      &__instagram {
        cursor: pointer;
      }

      &__info {
        display: flex;
        flex-direction: column;
        gap: 4rem;

        &--wrapper {
          display: flex;
          gap: 2rem;

          svg {
            width: 50px;
            height: 50px;
          }

          .contact-details {
            text-align: start;

            p:nth-child(1) {
              font-size: 2.4rem;
              font-weight: bold;
              color: $color-red-7;
            }
          }
        }
      }
    }


  }


}