@import "../../main.scss";

.back-button {
  display: flex;
  justify-content: center;
  margin-top: 3rem;
  font-size: 3rem;
  color: $color-red-7;
  cursor: pointer;

  svg {
    width: 3rem;
    margin-right: 1rem;
  }
}


.selected-project {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 99999999;
  background: linear-gradient(rgba(34, 34, 34, 1), rgba(34, 34, 34, .95));
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .active-image {
    opacity: 1;
  }

  &__selected-image {
    width: 70vw;
    height: 80vh;
    display: flex;
    align-items: center;
    margin-top: 5rem;

    @include respond(big-desktop) {
      height: 70vh;
    }

    @include respond(phone) {
      width: 100vw;
    }

    img {
      object-fit: contain;
      width: 100%;
      height: 100%;
    }

  }

  &__images {
    display: flex;
    flex-direction: row;
    margin: 5rem 0rem;
    // flex-wrap: wrap;
    // overflow-x: auto;
    // overflow-y: hidden;
    // margin: 5rem auto;
    // column-gap: 2rem;

    // @include respond(phone) {
    //   width: 100%;
    //   flex-direction: row;
    //   flex-wrap: nowrap;
    //   overflow-x: auto;
    // }

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
      // height: 20rem;
      opacity: .5;

    }
  }

  &__close-icon {
    // width: 100%;
    // text-align: end;
    position: fixed;
    right: 0;
    top: 0;
    cursor: pointer;

    svg {
      width: 5rem;
      height: 5rem;
      color: #fff;
      margin-top: 2rem;
      margin-right: 2rem;
    }
  }

  &__close-icon svg:hover {
    color: $color-text-dark;
    transition: color 200ms;
  }
}

.gallery {
  display: flex;
  flex-direction: row;
  column-gap: 5rem;
  min-height: 40rem;
  margin: auto;
  margin-top: 2.4rem;
  justify-content: center;
  width: 100%;
  flex-wrap: nowrap;
  overflow-x: scroll;

  @include respond(phone) {
    justify-content: space-between;
  }

  &__project {
    display: grid;
    grid-template-columns: repeat(2, 15rem);
    grid-template-rows: 30rem 15rem auto;
    gap: 1rem;

    &__image {
      overflow: hidden;


      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
        cursor: pointer;
        -webkit-transition: transform 800ms;
        -moz-transition: transform 800ms;
        transition: transform 800ms;
      }

      img:hover {
        transform: scale(1.1, 1.1);
      }
    }

    &__image:nth-child(1) {
      grid-row: 1/2;
      grid-column: 1/-1;
    }

    &__image:nth-child(2) {
      grid-row: 2/3;
      grid-column: 1/2;
    }

    &__image:nth-child(3) {
      grid-row: 2/3;
      grid-column: 2/3;
    }

    &--title {
      grid-row: 3/4;
      grid-column: 1/-1;
      font-size: 3rem;
      font-weight: 600;
      color: $color-text-dark;
    }
  }
}

.fade {
  animation: fade .8s linear;
}

@keyframes fade {
  from {
    opacity: 0;
  }

  to {
    transform: 1;
  }
}