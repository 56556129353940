@import "../../main.scss";

.services {
  font-size: 2rem;
  background-color: $color-background-light;
  padding-bottom: 12.8rem;

  &__title {
    padding-bottom: 8rem;
  }

  &__item {
    // display: flex;
    display: grid;
    grid-template-columns: 40rem 35rem 40rem;
    grid-template-rows: 50rem;
    column-gap: 1.5rem;
    justify-content: center;
    align-items: center;
    // height: 50rem;
    height: 100%;
    display: none;
    // width: 150rem;
    animation: fade 1s linear;


    @include respond(big-desktop) {
      grid-template-columns: 32rem 30rem 32rem;

    }

    @include respond(tab-land) {
      grid-template-columns: repeat(2, minmax(30rem, 35rem));
      grid-template-rows: 35rem 1fr;
    }

    @include respond(phone) {
      grid-template-columns: 1fr;
      grid-template-rows: 30rem 1fr 30rem;
      column-gap: 0;
      row-gap: 1.5rem;

    }

    &--img1,
    &--img2 {
      grid-column: 1/2;
      grid-row: 1/2;
      height: inherit;

      img {
        // width: 40rem;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    &--img2 {
      grid-column: 3/4;

      @include respond(tab-land) {
        grid-column: 2/3;
        grid-row: 1/2;
      }

      @include respond(phone) {
        grid-column: 1/2;
        grid-row: 3/4;
      }
    }

    &--info {
      grid-column: 2/3;
      display: flex;
      flex-direction: column;
      height: 60rem;
      justify-content: center;
      align-items: center;
      background-color: $color-background-dark;
      padding: 1rem;

      @include respond(tab-land) {
        grid-column: 1/3;
        grid-row: 2/3;
        width: 100%;
        height: 50rem;
        margin-top: 1.5rem;
      }

      @include respond(phone) {
        grid-row: 2/3;
        margin: 0;
      }

      img {
        object-fit: cover;
        border-radius: 50%;
        width: 20rem;
        height: 20rem;
        opacity: .7;

        @include respond(tab-land) {
          // width: 15rem;
          // height: 15rem;
        }
      }

      h3 {
        margin-top: 4.8rem;
        margin-bottom: 3.2rem;
        text-align: center;

      }

      ul {
        width: 100%;
        padding: 0px 3rem;
        margin-left: 5rem;

        @include respond(tab-land) {
          width: 70%;
          // height: 15rem;
        }

        li {
          list-style: square;
          font-size: 1.8rem;
          text-align: start;
          color: $color-text-dark;

          &::marker {
            color: $color-red-7;
          }
        }

        li:not(:first-child) {
          margin-top: 1.2rem;
        }
      }
    }
  }

  &__nav {
    display: flex;
    justify-content: center;
    column-gap: 6rem;
    margin-top: 9.6rem;

    @include respond(tab-land) {
      margin-top: 4.8rem;
    }

    @include respond(phone) {
      display: inline-block;
    }

    li {
      list-style: none;

      @include respond(phone) {
        margin-top: 1.5rem;
      }

    }

    li:hover {
      cursor: pointer;
    }

  }
}

.li-active {
  color: $color-red-7;
  text-decoration: underline;
}

.active {
  display: grid;
  animation: fade .8s linear;
}

@keyframes fade {
  from {
    opacity: 0;
  }

  to {
    transform: 1;
  }
}