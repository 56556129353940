@import "../../../main.scss";

.fix__menu {
  position: fixed;
  background: linear-gradient(rgba(34, 34, 34, 1), rgba(34, 34, 34, 1));

  transition: all 500ms ease-in-out;
}

.normal__menu {
  position: absolute;
}

.menu {
  display: flex;
  height: 7rem;
  padding: 1rem 0rem;
  width: 100%;
  z-index: 999999;
  flex-wrap: wrap;
  justify-content: space-between;

  @include respond(tab-land) {
    justify-content: space-between;
    padding: 0;
  }

  &__logo {
    display: flex;
    align-items: center;
    gap: 1rem;
    cursor: pointer;
    margin-left: 10rem;
    text-decoration: none;

    @include respond(tab-land) {
      margin: auto;
      position: absolute;
      left: 2rem;
      z-index: 8;

    }

    @include respond(phone) {
      margin: auto;
      position: absolute;
      left: 2rem;
      z-index: 8;
    }

    img {
      width: 5rem;
      height: 5rem;
    }

    &--title {
      color: #fff;
      width: max-content;
      font-size: 3rem;

      @include respond(tab-land) {
        justify-content: space-between;
        padding: 2rem 0;
      }

      @include respond(phone) {
        font-size: 2.4rem;
      }
    }
  }


  &__items {
    display: flex;
    align-items: center;
    justify-content: end;
    gap: 4rem;
    margin-right: 10rem;

    @include respond(tab-land) {
      position: fixed;
      opacity: 0;
      justify-content: center;
      flex-direction: column;
      width: 0;
      height: 100vh;
      padding: 2rem 0;
      margin: 0;
      // background: rgba(34, 34, 34, 1);
      background: linear-gradient(rgba(34, 34, 34, 0.9), rgba(34, 34, 34, 1));
      z-index: 10;
      gap: 1.8rem;
      // transition: opacity 500ms ease-in;
    }


    .item {
      list-style: none;
      cursor: pointer;
      font-size: 2.2rem;

      @include respond(tab-land) {
        font-size: 3rem;
      }
    }

    .active__item {
      a {
        color: $color-red-8;
      }
    }

    a {
      text-decoration: none;
      color: #fff;
    }

    a:hover {
      color: $color-red-7;
      transition: color 200ms ease-in-out;
    }
  }

  &__visible {
    width: 100%;
    opacity: 1;
    transition: opacity 500ms ease-in;
  }

  &__hide {
    animation: hide_menu 500ms ease-in;
  }

  &__button {
    display: none;
    margin-right: 3rem;
    cursor: pointer;

    @include respond(tab-land) {
      display: block;
      position: fixed;
      right: 0;
      top: 0;
      padding: 1rem 0;
      z-index: 10;

    }

    svg {
      width: 5rem;
      height: 5rem;
    }

  }
}

@keyframes hide_menu {
  0% {
    width: 100%;
    opacity: 1;
  }

  99% {
    width: 100%;
    opacity: 0;
  }

  100% {
    width: 0;
  }
}