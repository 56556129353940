@import "../../main.scss";

.projects {
  display: flex;
  flex-direction: column;
  width: 100vw;
  background-color: $color-background-light;

  padding-bottom: 12.8rem;

  &__title {
    padding: 12.8rem 0;
    text-align: center;
  }

  &__gallery {
    // display: grid;
    // grid-template-columns: repeat(3, 1fr);
    display: flex;
    margin-top: 8rem;
    width: 100%;
    // @include respond(tab-land) {
    //   grid-template-columns: 1fr 1fr;
    // }

    @include respond(tab-port) {
      grid-template-columns: 1fr;
      grid-template-rows: repeat(3, 30%);
    }

    @include respond(phone) {
      padding: 0;
      grid-template-rows: repeat(3, 30%);
    }

    .splide {
      width: 100%;
    }

    &--image {
      position: relative;
      overflow: hidden;
      // display: inline-block;
      -webkit-transition: transform .8s;
      -moz-transition: transform .8s;
      transition: transform .8s;

      img {
        width: 100%;
        height: 50rem;
        object-fit: cover;
        overflow: hidden;
        -webkit-transition: transform .8s;
        -moz-transition: transform .8s;
        transition: transform .8s;

        @include respond(tab-land) {
          height: 45rem;
        }


      }

      &:hover img {
        // animation: image-in 0.5s;
        // animation-fill-mode: forwards;
        -webkit-transform: scale(1.1, 1.1);
        -moz-transform: scale(1.1, 1.1);
        transform: scale(1.1, 1.1);
      }

      &:hover .image__info {
        // animation: image-info-in 0.5s ease-out;
        // animation-fill-mode: forwards;
        -webkit-transform: translateX(-18rem);
        -moz-transform: translateX(-18rem);
        transform: translateX(-18rem);

        @include respond(big-desktop) {
          -webkit-transform: translateX(-12rem);
          -moz-transform: translateX(-12rem);
          transform: translateX(-12rem);
        }
      }

      &:hover .image__gallery {
        // animation: image-info-in 0.5s ease-out;
        // animation-fill-mode: forwards;
        -webkit-transform: translateX(0%);
        -moz-transform: translateX(0%);
        transform: translateX(0%);
      }

      .image__filter {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        background: #252525;
        opacity: .5;
      }

      .image__info {
        position: absolute;
        background-color: $color-background-dark;

        bottom: 0;
        right: 0;
        z-index: 9999;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 40%;
        height: 20%;
        // animation: image-info-out 0.5s;
        // animation-fill-mode: forwards;
        -wekbit-transition: transform 800ms;
        -moz-transition: transform 800ms;
        transition: transform 800ms;

        @include respond(tab-land) {
          width: 50%;
          height: 30%;
        }

        @include respond(tab-port) {
          height: 20%;
        }

        @include respond(phone) {
          width: 40%;
          height: 20%;
        }

        span {
          width: 10%;
          background-color: $color-red-7;
          width: 20px;
          height: 5px;
          margin-left: 1.5rem;

          @include respond(tab-land) {
            display: none;
          }

          @include respond(tab-port) {
            display: flex;
          }
        }

        &--text {
          width: 90%;
          font-size: 2.4rem;
          margin-left: 10px;

          @include respond(big-desktop) {
            font-size: 1.8rem;
          }
        }
      }

      .image__info:hover+.image__gallery {
        // transform: scale(1.1, 1.1);
        -webkit-transform: translateX(0%);
        -moz-transform: translateX(0%);
        transform: translateX(0%);
      }

      .image__gallery {
        position: absolute;
        background-color: $color-red-7;
        color: #fff;
        bottom: 0;
        right: 0;
        z-index: 9999;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 2rem;
        height: 8rem;
        width: 18rem;
        -webkit-transform: translatex(100%);
        -moz-transform: translatex(100%);
        transform: translatex(100%);
        -webkit-transition: transform .8s;
        -moz-transition: transform .8s;
        transition: transform .8s;
        cursor: pointer;

        @include respond(big-desktop) {
          height: 6rem;
          width: 12rem;
          font-size: 1.8rem;
        }

        @include respond(tab-land) {
          height: 25%;
        }

        @include respond(tab-port) {
          height: 15%;
        }

        @include respond(phone) {
          height: 20%;
        }
      }
    }
  }
}

//******** ANIMATIONS ************
// @keyframes image-in {
//   // from {
//   //   transform: scale(1.0, 1.0);
//   // }

//   to {
//     transform: scale(1.1, 1.1);
//   }
// }

// @keyframes image-out {
//   // from {
//   //   transform: scale(1.1, 1.1);
//   // }

//   to {
//     transform: scale(1.0, 1.0);
//   }
// }

// @keyframes image-info-in {
//   // from {
//   //   transform: translateX(0);
//   // }

//   to {
//     transform: translateX(-150%);
//   }
// }

// @keyframes image-info-out {
//   // 0% {
//   //   transform: translateX(-150%);
//   // }

//   100% {
//     transform: translateX(0);
//     // right: 0;
//   }
// }







// .projects {
//   background-color: #ececec;
//   display: grid;
//   grid-template-columns: 15rem repeat(3, 1fr) 15rem;
//   padding: 9.6rem 0;
//   justify-content: center;
//   // column-gap: 1.6rem;

//   &__title {
//     grid-row: 1;
//     grid-column: 2;
//     font-size: 5.2rem;
//     justify-self: start;
//     margin-bottom: 4.8rem;
//   }

//   &__gallery {
//     grid-row: 2;
//     grid-column: 2 / 5;
//     display: flex;
//     flex-direction: row;
//     justify-content: space-between;
//     column-gap: 5rem;

//     &--image {
//       width: 100%;

//       img {
//         width: 100%;
//         height: 100%;
//         // width: 40rem;
//         // height: 50rem;
//         border-radius: 2%;
//         justify-self: center;
//       }

//     }
//   }
// }